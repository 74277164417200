<template>
	<main>

		<banner-section v-if="banner !== false" :banner="banner" />

		<section class="content" v-if="page.hasOwnProperty('longtitle')">
			<h1 v-if="page.longtitle.length > 0" v-html="page.longtitle" class="text-center" />

			<div class="text-center" v-html="page.content" />
		</section>

		<projects :projects="projects" />

	</main>
</template>

<script>
import { mapActions } from 'vuex';

import { bannerMixin } from '@/mixins/bannerMixin';
import { metaDataMixin } from '@/mixins/metaDataMixin';

import Projects from '@/components/ProjectsSection.vue';

var vm;

export default {
	mixins: [
		bannerMixin,
		metaDataMixin,
	],


	components: {
		Projects,
	},


	data()
	{
		return {
			projects: false,
		};
	},


	methods: {
		...mapActions([
			'getPage',
			'getProjects',
		]),


		initPage(alias)
		{
			vm.getPage(alias).then((p) => {
				vm.page = p;

				vm.getProjects({
					cat: p.alias,
				})
				.then((response) => {
					vm.projects = response;
				});
			});
		}
	},


	created()
	{
		vm = this;

		vm.initPage(vm.$route.params.alias);
	},


	watch: {
		$route(to)
		{
			vm.initPage(to.params.alias);
		},
	}
};
</script>
