<template>
	<main class="home">

		<banner-section v-if="banner !== false" :banner="banner" />

		<section>
			<div class="text-center">
				<div v-html="page.content" />
			</div>
		</section>

		<specialisms-section />

		<contact-cta />

		<inspiration-cta />

	</main>
</template>

<script>
import { bannerMixin } from '@/mixins/bannerMixin';
import { metaDataMixin } from '@/mixins/metaDataMixin';

import ContactCta from '@/components/ContactCta.vue';
import InspirationCta from '@/components/InspirationCta.vue';
import SpecialismsSection from '@/components/SpecialismsSection.vue';

export default {
	mixins: [
		bannerMixin,
		metaDataMixin,
	],


	components: {
		ContactCta,
		InspirationCta,
		SpecialismsSection,
	},
};
</script>
