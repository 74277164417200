<template>
	<main>
		<banner-section v-if="bannerAlt !== false" :banner="bannerAlt" />

		<gallery-section :gallery="images" />
	</main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { bannerMixin } from '@/mixins/bannerMixin';
import { metaDataMixin } from '@/mixins/metaDataMixin';

import GallerySection from '@/components/GallerySection.vue';

var vm;

export default {
	mixins: [
		bannerMixin,
		metaDataMixin,
	],


	components: {
		GallerySection,
	},


	data()
	{
		return {
			images: false,
		};
	},


	computed: {
		...mapGetters([
			'galleries',
		]),


		// // NOTE: The inspiation page has a slightly different way to setup the banner text
		// banner()
		// {
		// 	let banner = false;
		//
		// 	// Return false, while the page isn't loaded yet
		// 	if (!vm.page.hasOwnProperty('variables')) return banner;
		//
		// 	if (vm.page.variables.hasOwnProperty('bannerImage'))
		// 	{
		// 		banner = {
		// 			image: vm.page.variables.bannerImage
		// 		};
		//
		// 		// If a title was set, set it as the text
		// 		let text = vm.page.longtitle.length > 0 ? '<h1>' + vm.page.longtitle + '</h1>' : '';
		//
		// 		// Add the content too
		// 		text += vm.page.content;
		//
		// 		banner.text = text;
		// 	}
		//
		// 	return banner;
		// },


		gallery()
		{
			for (let i = 0; i < vm.galleries.length; i++)
			{
				let g = vm.galleries[i];

				if (g.path == vm.$route.path) return g;
			}

			return false;
		},
	},


	methods: {
		...mapActions([
			'getGallery',
			'getPhotos',
		]),
	},


	created()
	{
		vm = this;


		if (vm.gallery === false)
		{
			vm.getGallery(vm.$route.path)
			.then((response) => {
				if (response === false)
				{
					let params = {
						path: vm.$route.path,
						params: {
							insp: 1,
							parent: vm.page.id,
							w: window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight,
						},
					};

					vm.getPhotos(params)
					.then((photos) => {
						vm.images = photos.images;
					});
				}
				else
				{
					vm.images = response;
				}
			});
		}
		else
		{
			vm.images = vm.gallery.images;
		}
	},
};
</script>
