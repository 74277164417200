<template>
	<section class="cta" v-if="page.hasOwnProperty('variables')" :style="background">
		<div class="cta-text">
			<div v-html="page.variables.ctaText" />

			<router-link :to="{ name: 'Contact', params: {} }" class="button">
				Neem contact op
			</router-link>
		</div>
	</section>
</template>

<script>
import { mapActions } from 'vuex';

var vm;

export default {
	methods: {
		...mapActions([
			'getPage',
		]),
	},


	data()
	{
		return {
			page: {},
		};
	},


	computed: {
		background()
		{
			let url = vm.page.variables.ctaImage.full;

			return "background-image: url('" + url + "');"
		},
	},


	created()
	{
		vm = this;

		vm.getPage('contact')
		.then((p) => {
			vm.page = p;
		});
	},
}
</script>
