<template>
	<main>

		<banner v-if="banner !== false" :banner="banner" />

		<section class="contact">

			<google-map />

			<div>
				<h2>
					Contact
				</h2>

				<ul>
					<li class="headings">
						{{ contactData.name }}
					</li>
					<li>
						{{ contactData.address }}
					</li>
					<li>
						{{ contactData.postcode }}
						{{ contactData.city }}
					</li>
					<li>
						{{ contactData.phone.text }}
					</li>
					<li>
						{{ contactData.email }}
					</li>

					<li>
						<a :href="contactData.facebook" target="_blank">
							{{ fbHandle }}
						</a>
					</li>
				</ul>

				<div>
					<a :href="'tel:' + contactData.phone.link" class="button">
						Bel ons
					</a>

					<a :href="'mailto:' + contactData.email" class="button">
						Mail ons
					</a>
				</div>
			</div>

			<contact-form />

		</section>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';

import { bannerMixin } from '@/mixins/bannerMixin';
import { metaDataMixin } from '@/mixins/metaDataMixin';

import ContactForm from '@/components/ContactForm.vue';
import GoogleMap from '@/components/GoogleMap.vue';

var vm;

export default {
	mixins: [
		metaDataMixin,
		bannerMixin,
	],


	components: {
		ContactForm,
		GoogleMap,
	},


	computed: {
		...mapGetters([
			'contactData',
		]),


		fbHandle()
		{
			return '@' + vm.contactData.facebook.split('.com/')[1];
		},
	},


	created()
	{
		vm = this;
	},
};
</script>
