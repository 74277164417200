<template>
	<router-link :to="route(project.path)">
		<figure :style="background">
			<figcaption>
				<span class="headings">
					{{ project.name }}
				</span>

				<small>
					{{ project.category.join(' / ') }}
				</small>
			</figcaption>
		</figure>
	</router-link>
</template>

<script>
var vm;

export default {
	props: {
		project: Object,
	},


	computed: {
		background()
		{
			let url = vm.project.image.thumbnail;

			return "background-image: url('" + url + "');"
		},
	},


	methods: {
		route(path)
		{
			return path.replace('.json', '');
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
