var vm;

import { mapActions } from 'vuex';

import BannerSection from '@/components/BannerSection.vue';

export const bannerMixin = {
	components: {
		BannerSection,
	},


	data()
	{
		return {
			page: {},
		};
	},


	computed: {
		banner()
		{
			let banner = false;

			// Return false, while the page isn't loaded yet
			if (!Object.prototype.hasOwnProperty.call(vm.page, 'variables')) return banner;

			if (Object.prototype.hasOwnProperty.call(vm.page.variables, 'bannerImage'))
			{
				banner = {
					image: vm.page.variables.bannerImage
				};

				if (Object.prototype.hasOwnProperty.call(vm.page.variables, 'bannerText'))
				{
					banner.text = vm.page.variables.bannerText;
				}
			}

			return banner;
		},


		bannerAlt()
		{
			let banner = false;

			// Return false, while the page isn't loaded yet
			if (!Object.prototype.hasOwnProperty.call(vm.page, 'variables')) return banner;

			if (Object.prototype.hasOwnProperty.call(vm.page.variables, 'bannerImage'))
			{
				banner = {
					image: vm.page.variables.bannerImage
				};

				// If a title was set, set it as the text
				let text = vm.page.longtitle.length > 0 ? '<h1>' + vm.page.longtitle + '</h1>' : '';

				// Add the content too
				text += vm.page.content;

				banner.text = text;
			}

			return banner;
		},
	},


	methods: {
		...mapActions([
			'getPage',
		]),
	},


	created()
	{
		vm = this;

		let path = vm.$route.path == '/' ? '' : vm.$route.path.substr(1);
		vm.getPage(path).then((p) => {
			vm.page = p;
		});
	},
}
