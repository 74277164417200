<template>

	<figure :style="background" />

</template>

<script>
var vm;

export default {
	props: {
		item: Object,
	},


	computed: {
		background()
		{
			let url = vm.item.thumbnail;

			return "background-image: url('" + url + "');"
		}
	},


	created()
	{
		vm = this;
	},
}
</script>
