<template>
	<section v-if="Object.keys(ordered).length > 0" class="specialisms">
		<specialism	v-for="(s, id) in ordered" :key="id" :data="s" />
	</section>
</template>

<script>
import { mapGetters } from 'vuex';

import Specialism from './SpecialismLink.vue';

var vm;

export default {
	components: {
		Specialism,
	},


	computed: {
		...mapGetters([
			'specialisms',
		]),


		ordered()
		{
			let o = {};

			for (let i = 0; i < vm.specialisms.length; i++)
			{
				let s = vm.specialisms[i];

				o[s.menuindex] = s;
			}

			return o;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
