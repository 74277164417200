import Vue from 'vue';
import Router from 'vue-router';

import ContactPage from '@/views/ContactPage.vue';
import HomePage from '@/views/HomePage.vue';
import InspirationPage from '@/views/InspirationPage.vue';
import ProjectPage from '@/views/ProjectPage.vue';
import SpecialismPage from '@/views/SpecialismPage.vue';
import SpecialismsPage from '@/views/SpecialismsPage.vue';
import TextPagePage from '@/views/TextPage.vue';
import VacanciesPage from '@/views/VacanciesPage.vue';
import VacancyPage from '@/views/VacancyPage.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior () {
        return { x: 0, y: 0 }
    },
	routes: [
		{
			path: '/',
			name: 'HomePage',
			component: HomePage,
		},

		{
			path: '/contact',
			name: 'ContactPage',
			component: ContactPage,
		},

		{
			path: '/inspiratie',
			name: 'InspirationPage',
			component: InspirationPage,
		},

		{
			path: '/specialismen',
			name: 'SpecialismsPage',
			component: SpecialismsPage,
		},
		{
			path: '/specialismen/:alias',
			name: 'SpecialismPage',
			component: SpecialismPage,
			meta: {
				transition: 'swipe',
			},
		},

		{
			path: '/project/:alias',
			name: 'ProjectPage',
			component: ProjectPage,
			meta: {
				transition: 'swipe',
			},
		},

		{
			path: '/vacatures',
			name: 'VacanciesPage',
			component: VacanciesPage,
		},
		{
			path: '/vacatures/:alias',
			name: 'VacancyPage',
			component: VacancyPage,
			meta: {
				transition: 'swipe',
			},
		},

		{
			path: '/:alias',
			name: 'TextPage',
			component: TextPagePage,
		},
	]
});
