<template>
	<main>

		<banner-section v-if="banner !== false" :banner="banner" />

		<section class="content" v-if="page.hasOwnProperty('longtitle')">
			<h1 v-if="page.longtitle.length > 0" v-html="page.longtitle" />
		</section>

		<gallery :gallery="images" />

	</main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { bannerMixin } from '@/mixins/bannerMixin';
import { metaDataMixin } from '@/mixins/metaDataMixin';

import Gallery from '@/components/GallerySection.vue';

var vm;

export default {
	mixins: [
		bannerMixin,
		metaDataMixin,
	],


	components: {
		Gallery,
	},


	data()
	{
		return {
			images: false,
		};
	},


	computed: {
		...mapGetters([
			'galleries',
		]),


		gallery()
		{
			for (let i = 0; i < vm.galleries.length; i++)
			{
				let g = vm.galleries[i];

				if (g.path == vm.$route.path) return g;
			}

			return false;
		},
	},


	methods: {
		...mapActions([
			'getGallery',
			'getPage',
			'getPhotos',
		]),


		initPage(alias)
		{
			vm.getPage(alias).then((p) => {
				vm.page = p;
			});


			if (vm.gallery === false)
			{
				vm.getGallery(vm.$route.path)
				.then((response) => {
					if (response === false)
					{
						let params = {
							path: vm.$route.path,
							params: {
								parent: vm.page.id,
								w: window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight,
							},
						};

						vm.getPhotos(params)
						.then((photos) => {
							vm.images = photos.images;
						});
					}
					else
					{
						vm.images = response;
					}
				});
			}
			else
			{
				vm.images = vm.gallery.images;
			}
		}
	},


	created()
	{
		vm = this;

		vm.initPage(vm.$route.params.alias);
	},


	watch: {
		$route(to)
		{
			vm.initPage(to.params.alias);
		},
	}
};
</script>
