<template>
	<main>
		<section class="full-text">
			<div>

				<h1>
					{{ page.longtitle }}
				</h1>

				<div v-html="page.content" />

			</div>
		</section>
	</main>
</template>

<script>
import { bannerMixin } from '@/mixins/bannerMixin';
import { metaDataMixin } from '@/mixins/metaDataMixin';

export default {
	mixins: [
		bannerMixin,
		metaDataMixin,
	],
};
</script>
