<template>
	<router-link v-if="item.props.children.length === 0" :to="{ path: route(item.path, item.base) }" exact>
		{{ item.name }}
	</router-link>

	<div v-else>
		<router-link :to="{ path: route(item.path, item.base) }" exact>
			{{ item.name }}
		</router-link>

		<router-link v-for="(child, id) in item.props.children" :key="id" :to="{ path: route(child.path, item.base) }" class="subnav-item">
			{{ child.path | name }}
		</router-link>
	</div>
</template>

<script>
export default {
	props: {
		item: Object,
	},


	filters: {
		name: (input) => {
			// Split into breadcrumb trail
			let bc = input.split('/');

			// Get the last item
			let item = bc[(bc.length - 1)];

			// Replace unwanted characters
			item = item.replace('.json', '').replace('-', ' ');

			return item;
		}
	},


	methods: {
		route(path, base)
		{
			let r = path.replace('.json', '').replace(base, '');

			if (r.substr(-1) == '/') r = r.substr(0, r.length - 1);

			return r;
		},
	},
}
</script>
