<template>
	<main>

		<banner-section v-if="bannerAlt !== false" :banner="bannerAlt" />


		<transition :name="transition" mode="out-in">

			<section class="gallery projects" v-if="vacancies !== false">

				<vacancy-preview v-for="(v, id) in vacancies" :key="id" :page="v" />

				<a class="project" v-if="hasVideo" href="#" @click.prevent="showVideo = !showVideo">
					<figure :style="thumbnail">
						<figcaption>
							<span class="headings has-icon">
								<i class="fad fa-play-circle"></i>

								Bekijk onze wervingsvideo
							</span>
						</figcaption>
					</figure>
				</a>

			</section>

		</transition>

		<transition name="fade" mode="out-in">
			<div class="modal" v-if="showVideo" @click.self="showVideo = false">
				<div class="modal-body video">
					<video width="320" height="180" controls>
						<source :src="video" type="video/webm" />
						<source :src="videoIOS" type="video/mp4" />
						Uw browser ondersteunt de video tag niet.
					</video>
				</div>
			</div>
		</transition>

	</main>
</template>

<script>
import { mapGetters } from 'vuex';

import { bannerMixin } from '@/mixins/bannerMixin';
import { metaDataMixin } from '@/mixins/metaDataMixin';

import VacancyPreview from '@/components/VacancyPreview.vue';

var vm;

export default {
	mixins: [
		bannerMixin,
		metaDataMixin,
	],


	components: {
		VacancyPreview,
	},


	data()
	{
		return {
			showVideo: false,
		};
	},


	computed: {
		...mapGetters([
			'base',
			'pages',
		]),


		hasVideo()
		{
			return Object.prototype.hasOwnProperty.call(vm.page, 'variables') && Object.prototype.hasOwnProperty.call(vm.page.variables, 'video') && Object.prototype.hasOwnProperty.call(vm.page.variables, 'image')
		},


		thumbnail()
		{
			return "background-image: url('" + vm.page.variables.image.full + "');"
		},


		transition()
		{
			return vm.vacancies.length === 0 ? 'fade' : 'fade-in-up';
		},


		vacancies()
		{
			let vacancies = [];

			if (vm.page !== false && Object.keys(vm.page).length > 0)
			{
				for (let i = 0; i < vm.pages.length; i++)
				{
					let p = vm.pages[i],
					path = '/' + p.path;

					// If it's a child, push it
					if (vm.page.children.indexOf(path) >= 0) vacancies.push(p);
				}
			}

			return vacancies;
		},


		video()
		{
			return vm.hasVideo ? vm.base + vm.page.variables.video : '';
		},


		videoIOS()
		{
			return vm.hasVideo && Object.prototype.hasOwnProperty.call(vm.page.variables, 'videoIos') ? vm.base + vm.page.variables.videoIos : '';
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
