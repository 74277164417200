<template>
	<transition :name="transition" mode="out-in">

		<section class="gallery projects" v-if="projects !== false">

			<project-preview v-for="(project, id) in projects" :key="'project-' + id" :project="project" />

			<router-link :to="{ name: 'Specialisms' }" class="back-button">
				<figure>
					<figcaption>
						Terug naar de specialismen
					</figcaption>
				</figure>
			</router-link>

		</section>

	</transition>
</template>

<script>
import ProjectPreview from './ProjectPreview.vue';

var vm;

export default {
	components: {
		ProjectPreview,
	},


	props: {
		projects: [Array, Boolean],
	},


	computed: {
		transition()
		{
			return vm.projects === false ? 'fade' : 'fade-in-up';
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
