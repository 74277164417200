import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';

import router from './router.js';
import store from './store.js';

import './registerServiceWorker';


Vue.use(VueMeta, {
	// optional pluginOptions
	refreshOnceOnNavigation: true
});


Vue.config.productionTip = false;


// Determine the image size
var w = window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight;
if (w <= 640)
{
	Vue.prototype.$size = 640;
}
else if (w <= 800)
{
	Vue.prototype.$size = 800;
}
else if (w <= 1280)
{
	Vue.prototype.$size = 1280;
}
else
{
	Vue.prototype.$size = 'full';
}


new Vue({
	router,
	store,
	render: h => h(App),
	mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app');
