var vm;

export const metaDataMixin = {
	data()
	{
		return {
			page: {},
		};
	},


	computed: {
		metaData()
		{
			// If no page is loaded yet, return false
			if (Object.keys(vm.page).length === 0) return false;

			let meta = {
				description: vm.page.description,
				keywords: vm.page.keywords,
				title: vm.page.longtitle.length > 0 ? vm.page.longtitle : vm.page.pagetitle,
			}

			return meta;
		},
	},


	created()
	{
		vm = this;
	},


	watch: {
		page:
		{
			handler: () => {
				vm.$emit('loaded', vm.metaData);
			},
			deep: true,
		},
	}
}
