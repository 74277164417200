<template>

	<transition name="fade" mode="out-in">

		<article v-if="loaded">

			<app-header />


			<app-footer />


			<transition :name="transitionName" :mode="transitionMode" appear>
				<router-view :key="$route.path" @loaded="setMetaData" />
			</transition>

		</article>


		<div class="loader" v-if="!loaded">
			<i class="fas fa-spinner fa-spin fa-3x"></i>
		</div>
	</transition>

</template>

<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';


import AppFooter from "@/components/AppFooter";
import AppHeader from "@/components/AppHeader";

var vm;

export default {
	components: {
		AppFooter,
		AppHeader,
	},


	data()
	{
		return {
			metaData: {
				description: '',
				keywords: '',
				title: '',
			},
			transitionName: 'fade',
			transitionMode: 'out-in',
		};
	},


	computed: {
		...mapGetters([
			'pages',
			'structure',
		]),


		loaded()
		{
			return vm.structure.length > 0 && vm.structure.length === vm.pages.length;
		},
	},


	metaInfo ()
	{
		return {
			title: vm.metaData.title,
			titleTemplate: '%s - Roche Renovaties',
			htmlAttrs: {
				lang: 'nl',
				amp: true,
			},
			meta: [
				{
					charset: 'utf-8'
				},
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1'
				},
				{
					name: 'description',
					content: vm.metaData.description,
				},
				{
					name: 'keywords',
					content: vm.metaData.keywords,
				},
			]
		};
	},


	methods: {
		...mapActions([
			'getContactData',
			'getStructure',
			'loadPages',
		]),


		setMetaData(md)
		{
			vm.metaData = md;
		},
	},


	created()
	{
		vm = this;

		let params = {
			w: window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight,
			t: new Date().getTime(),
		};

		vm.getContactData();

		vm.getStructure(params).then(() => {
			params = {
				w: window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight,
			};

			vm.loadPages(params);
		});
	},


	watch: {
		$route(to)
		{
			if (Object.prototype.hasOwnProperty.call(to.meta, 'transition'))
			{
				vm.transitionName = to.meta.transition;
			}
			else
			{
				vm.transitionName = 'fade-in-up';
			}

			if (Object.prototype.hasOwnProperty.call(to.meta, 'transitionMode'))
			{
				vm.transitionMode = to.meta.transitionMode;
			}
			else
			{
				vm.transitionMode = 'out-in';
			}
		},
	}
}
</script>

<style lang="scss">

	@import "assets/css/all.min.css";
	@import "assets/scss/app.scss";

</style>
