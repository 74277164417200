<template>
	<footer v-if="Object.keys(contactData).length > 0" :data-specialisms="specialisms.length">

		<div>
			<ul class="icon-list">
				<li class="headings">
					{{ contactData.name }}
				</li>

				<li>
					<i class="fas fa-map-marker-alt"></i>
                    {{ contactData.address }}
				</li>

				<li>
					{{ contactData.postcode }} {{ contactData.city }}
				</li>

				<li>
					<i class="fas fa-phone-alt"></i>

					<a :href="'tel:' + contactData.phone.link">
						{{ contactData.phone.text }}
					</a>
				</li>

				<li>
					<i class="fas fa-envelope"></i>

					<a :href="'mailto:' + contactData.email">
						{{ contactData.email }}
					</a>
				</li>

				<li>
					<i class="fab fa-facebook-f"></i>

					<a :href="contactData.facebook" target="_blank">
						{{ fbHandle }}
					</a>
				</li>

				<li>
					<i class="fab fa-instagram"></i>

					<a :href="contactData.instagram" target="_blank">
						{{ igHandle }}
					</a>
				</li>
			</ul>
		</div>


		<div>
			<ul>
				<li class="headings">
					Specialismen
				</li>

				<li v-for="(s, id) in specialisms"  :key="id">
					<router-link :to="{ name: 'Specialism', params: { alias: s.alias} }">
						{{ s | title }}
					</router-link>
				</li>
			</ul>
		</div>

		<div class="credits">
			<small>
				<span>
					<i class="fal fa-copyright"></i>

					<span>
						Copyright {{ year }} {{ contactData.name }}
					</span>
				</span>

				<span v-if="terms">
					<i v-if="terms.variables.hasOwnProperty('icon') && terms.variables.icon.length > 0" :class="terms.variables.icon"></i>

					<router-link :to="{ name: 'TextPage', params: { alias: terms.alias} }">{{ terms | title }}</router-link>
				</span>

				<span v-if="privacy">
					<i v-if="privacy.variables.hasOwnProperty('icon') && privacy.variables.icon.length > 0" :class="privacy.variables.icon"></i>

					<router-link :to="{ name: 'TextPage', params: { alias: privacy.alias} }">{{ privacy | title }}</router-link>
				</span>
			</small>

			<small>
				<span>
					<i class="far fa-desktop"></i>

					<span>
						Website realisatie
					</span>

					<a href="https://dizero.nl" target="_blank">
						Dizero
					</a>
				</span>

				<span>
					<i class="fas fa-code-branch"></i>

					<span>
						v{{ version }}
					</span>
				</span>
			</small>

		</div>

	</footer>
</template>

<script>
import { mapGetters } from 'vuex';

import pkg from '@/../package.json';

var vm;

export default {
	filters: {
		title(page)
		{
			return page.menutitle.length > 0 ? page.menutitle : page.pagetitle;
		},
	},


	data()
	{
		return {
			version: pkg.version,
			year: new Date().getFullYear(),
		};
	},


	computed: {
		...mapGetters([
			'contactData',
			'pages',
		]),


		fbHandle()
		{
			return '@' + vm.contactData.facebook.split('.com/')[1];
		},


		igHandle()
		{
			return '@' + vm.contactData.instagram.split('.com/')[1];
		},


		privacy()
		{
			for (let i = 0; i < vm.pages.length; i++)
			{
				let p = vm.pages[i];

				if (p.alias.indexOf('privacy') >= 0) return p;
			}

			return false;
		},


		specialisms()
		{
			let specs = [];

			for (let i = 0; i < vm.pages.length; i++)
			{
				let p = vm.pages[i];

				if (p.template == 2)
				{
					specs.push(p);
				}
			}

			return specs;
		},


		terms()
		{
			for (let i = 0; i < vm.pages.length; i++)
			{
				let p = vm.pages[i];

				if (p.alias == 'algemene-voorwaarden') return p;
			}

			return false;
		},
	},


	created()
	{
		vm = this;
	},
};
</script>
