<template>

	<transition :name="transition" mode="out-in">

		<section class="gallery" v-if="gallery !== false">
			<div class="clickable" v-for="(item, id) in gallery" :key="'gi-' + id" @click.prevent="setActiveId(id)">
				<gallery-item :item="item" />
			</div>

			<LightGallery
				v-if="activeId !== false"
				:images="images"
				:index="activeId"
				:disable-scroll="true"
				@close="activeId = false"
			/>
		</section>

		<div class="loader" v-else>
			<i class="fas fa-spinner fa-spin fa-2x"></i>
		</div>

	</transition>

</template>

<script>
import { LightGallery } from 'vue-light-gallery';

import GalleryItem from './GalleryItem.vue';

var vm;

export default {
	components: {
		GalleryItem,
		LightGallery,
	},


	props: {
		gallery: {
			type: [Array, Boolean],
			default: false,
		},
	},


	data()
	{
		return {
			activeId: false,
		};
	},


	computed: {
		images()
		{
			let imgs = [];

			for (let i = 0; i < vm.gallery.length; i++)
			{
				let img = vm.gallery[i];

				imgs.push({
					// title: img.name,
					url: img.full,
				});
			}

			return imgs;
		},


		transition()
		{
			return vm.gallery === false ? 'fade' : 'fade-in-up';
		},
	},


	methods: {
		setActiveId(val)
		{
			vm.activeId = val;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
