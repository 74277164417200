<template>
	<section :class="classes" :style="background">
		<div v-if="banner.hasOwnProperty('text')" v-html="banner.text" />
	</section>
</template>

<script>
var vm;

export default {
	props: {
		banner: Object,
		customClass: {
			type: String,
			default: '',
		},
	},


	computed: {
		background()
		{
			let url = vm.banner.image[vm.$size];

			return "background-image: url('" + url + "');"
		},


		classes()
		{
			let cl = ['banner', 'text-center', vm.customClass];

			if (Object.prototype.hasOwnProperty.call(vm.banner, 'text') && vm.banner.text.length > 0)
			{
				cl.push('darkened');
			}

			return cl.join(' ');
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
