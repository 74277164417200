<template>
	<div class="map" v-if="Object.keys(contactData).length > 0">
		<i class="fas fa-spinner fa-2x fa-spin"></i>
		<iframe :src="src" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

var vm;

export default {
	computed: {
		...mapGetters([
			'contactData',
		]),


		src()
		{
			let apiKey = 'AIzaSyDO3HNWBciKgWdE2SSadkU73G2w8XEFOBo';
			let src = 'https://www.google.com/maps/embed/v1/place?key=' + apiKey;

			let data = [
				vm.contactData.address,
				vm.contactData.postcode,
				vm.contactData.city,
			];

			src += '&q=' + data.join(',').split(' ').join('+');

			src += '&zoom=17';

			return src;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
