<template>
	<router-link
		:to="{ name: 'Specialism', params: { alias: data.alias } }"
		class="clickable"
	>
		<figure :style="background" />

		<h4>
			{{ data.pagetitle }}
		</h4>
	</router-link>
</template>

<script>
var vm;

export default {
	props: {
		data: Object,
	},


	computed: {
		background()
		{
			if (!Object.prototype.hasOwnProperty.call(vm.data, 'variables') || !Object.prototype.hasOwnProperty.call(vm.data.variables, 'bannerImage')) return '';

			let url = vm.data.variables.bannerImage.thumbnail;

			return "background-image: url('" + url + "');"
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
