<template>
	<header>
		<router-link :to="{ name: 'HomePage', params: {} }" exact>
			<img src="img/logo.svg" alt="Roche Renovaties" />
		</router-link>

		<nav :class="open ? 'open' : ''">
			<router-link :to="{ name: 'HomePage', params: {} }" exact>
				Home
			</router-link>

			<menu-item
				v-for="(item, id) in nav"
				:key="'nav-' + id"
				:item="item"
			/>
		</nav>

		<a href="#" @click.prevent="open = !open" class="toggle">
			<span>
				Menu
			</span>

			<span class="toggle-button">
				<span></span>
				<span></span>
				<span></span>
			</span>
		</a>
	</header>
</template>

<script>
import { mapGetters } from 'vuex';

import MenuItem from './MenuItem.vue';

var vm;

export default {
	components: {
		MenuItem,
	},


	data()
	{
		return {
			open: false,
			selected: 0,
		};
	},


	computed: {
		...mapGetters([
			'structure',
		]),


		nav()
		{
			let nav = [];

			for (var i = 0; i < vm.structure.length; i++)
			{
				let p = vm.structure[i];

				if (p.path != '/' && p.parent == 0 && p.hidemenu === false)
				{
					nav.push(p);
				}
			}

			return nav;
		},
	},


	created()
	{
		vm = this;
	},


	watch: {
		$route()
		{
			vm.open = false;
		},
	}
}
</script>
