<template>
	<main>

		<banner-section v-if="banner !== false" :banner="banner" />

		<section class="columns">

			<div class="column content">
				<div v-html="page.content" />

				<router-link v-if="!hasExtraContent" class="button" :to="{ name: 'Contact' }">
					Neem contact op
				</router-link>
			</div>

			<div class="column" v-if="hasExtraContent">
				<div v-html="page.variables.extraContent" />

				<router-link v-if="hasExtraContent" class="button" :to="{ name: 'Contact' }">
					Neem contact op
				</router-link>
			</div>
		</section>

	</main>
</template>

<script>
import { mapGetters } from 'vuex';

import { bannerMixin } from '@/mixins/bannerMixin';
import { metaDataMixin } from '@/mixins/metaDataMixin';

var vm;

export default {
	mixins: [
		bannerMixin,
		metaDataMixin,
	],


	computed: {
		...mapGetters([
			'pages',
		]),


		hasExtraContent()
		{
			return Object.prototype.hasOwnProperty.call(vm.page, 'variables') && Object.prototype.hasOwnProperty.call(vm.page.variables, 'extraContent')
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
