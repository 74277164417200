<template>
	<router-link :to="{ name: 'Vacancy', params: { alias: page.alias }}">
		<figure :style="background">
			<figcaption>
				<span class="headings">
					{{ title }}
				</span>
			</figcaption>
		</figure>
	</router-link>
</template>

<script>
var vm;

export default {
	props: {
		page: Object,
	},


	computed: {
		background()
		{
			let url = vm.page.variables.bannerImage.thumbnail;

			return "background-image: url('" + url + "');"
		},


		title()
		{
			return vm.page.longtitle.length > 0 ? vm.page.longtitle : vm.page.pagetitle;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
