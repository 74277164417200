<template>
	<div :class="classes">
		<i class="fas fa-times clickable" @click="close"></i>
	</div>
</template>

<script>
var vm;

export default {
	props: {
		alert: Object,
	},


	computed: {
		classes()
		{
			let cl = ['alert'];

			if (Object.prototype.hasOwnProperty.call(vm.alert, 'customClass'))
			{
				cl.push(vm.alert.customClass);
			}

			return cl.join(' ');
		},
	},


	methods: {
		close()
		{
			vm.$emit('close');
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
